<template>
  <div class="main">
    <div class="top-wrap goodscenter-top-back">
      <div class="top-box">
        <div class="top-box-body" >
          <h3 class="top-box-title" style="color:black">产品中心</h3>
          <div class="top-box-desc" >
            <p style="color:black">从基础设施到行业应用领域，腾讯云提供完善的产品体系，助力您的业务腾飞</p>
          </div>
          <a href="https://cloud.tencent.com/product" class="top-box-btn">查看全部云产品</a>
          <!-- <div class="top-box-png">
            <img src="../assets/全部商品.svg" alt="" />
          </div> -->
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="content auto-1200" v-for="item,index in goodsCenterData" v-bind:key="index">
        <h1 class="text-center gtitle-0001">{{item.sort}}</h1>
        <div class="goods-banner">
          <div class="goods-banner-title">{{item.desc[0]}}</div>
          <p>{{item.desc[1]}}</p>
          <!-- <div class="goods-banner-btn">
            <a class="layui-btn layui-btn-disabled">详情了解</a>
          </div> -->
        </div>
        <p class="describe-p1 text-center"></p>
        <div class="goods-card-list">
          <a :href="item1.href" class="goods-card hangkuai" v-for="item1,index1 in item.list" v-bind:key="index1">
            <div class="goods-card-box" v-if="item1.goodsName !='' ">
              <div class="goods-card-icon hangkuai">
                <img :src="item1.icon[0]" alt="" />
                <img
                  src="@/assets/img/goodscenter/icon/cvmblue.svg"
                  class="dis_none"
                  alt=""
                />
              </div>
              <div class="goods-card-text hangkuai">
                <div class="goods-card-tit">{{item1.goodsName}}</div>
                <div class="goods-card-desc">{{item1.shortDesc}}</div>
              </div>
            </div>
          </a>

          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Company",
  data() {
    return {
      goodsCenterData:require("../../static/json/goodsCenter/goodsCenterData.json")
    };
  },
  methods: {},
  mounted: function () {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../assets/css/public.css");
@import url("../assets/css/goodscenter.css");
.goodscenter-top-back{
  background-image: url('https://cloudcache.tencentcs.com/open_proj/proj_qcloud_v2/gateway/product/css/img/banner-pc.png');
  background-size: 110% 100%;
  /* background-position-y: -200px; */
}
</style>
